import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import COLORs from "shared/libs/color";
import { FONT_SIZE, FONT_WEIGHT } from "shared/libs/constants";
import Text from "components/Text";
import InputText from "components/InputText";
import RoundButton from "components/RoundButton";
import Image from "components/Image";
import Svg from "components/Svg";
import AttentionModal from "./components/AttentionModal";
import UsageModal from "./components/UsageModal";
import { useDispatch, useSelector } from "react-redux";
import { issueYouTubeReviewLinkFromLP } from "app/actions/OneTimeToken";
import { extractYouTubeVideoId } from "shared/libs/util_func";
import media from "shared/libs/media";
import crunchLogo from "assets/images/crunch-logo.svg";
import { ReactComponent as crunchTextLogo } from "assets/images/crunch-text-logo.svg";
import { ReactComponent as usage1 } from "assets/images/usage1.svg";
import { ReactComponent as usage2 } from "assets/images/usage2.svg";
import { ReactComponent as usage3 } from "assets/images/usage3.svg";
import { ReactComponent as movie } from "assets/images/movie.svg";
import { ReactComponent as chevron } from "assets/images/rightChevron.svg";
import reviewTrialVideo from "assets/images/review-trial.mp4";

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: #252525;

  &::before {
    display: block;
    padding-top: 67%;
    content: "";
  }
  ${media.tablet`
    &:before {
      padding-top: 50%;
    }
  `}
  ${media.sp`
    &:before {
      padding-top: 200%;
    }
  `}
`;
const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${media.tablet`
    flex-direction: column;
  `}
  ${media.sp`
    flex-direction: column;
  `}
`;
const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  text-align: center;
  ${media.sp`
    padding: 22px 0;
  `}
`;
const MovieSection = styled.div`
  position: relative;
  z-index: 22;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding-right: 24px;
  ${media.tablet`
    height: 50%;
    width: 100%;
    justify-content: flex-start;
  `}
  ${media.sp`
    height: 50%;
    width: 100%;
  `}
`;
const MovieFlame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 700px;
  aspect-ratio: 1;
  ${media.tablet`
    max-height: 440px;
    height: 75%;
    width: 85%;
  `}
  ${media.sp`
    max-height: 334px;
    height: 85%;
    width: 100%;
  `}
`;
const PcDisplay = styled.div`
  position: absolute;
  top: 0;
  width: 94%;
  height: 100%;
  padding: 8% 10px 0 0;
  background-color: ${COLORs.black};
  border-radius: 0 10px 0 0;
`;
const PcBodyTop = styled.div`
  position: absolute;
  bottom: -2%;
  z-index: 23;
  width: 100%;
  height: 2.5%;
  background-color: #d8d8d8;
  border-radius: 0 0 8px;
`;
const PcBodyBottom = styled.div`
  position: absolute;
  bottom: -4.5%;
  z-index: 22;
  width: 100%;
  height: 5%;
  background-color: #aaa;
  border-radius: 0 0 8px;
`;
const LinkSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  ${media.tablet`
    height: 50%;
    width: 100%;
  `}
  ${media.sp`
    height: 50%;
    width: 100%;
  `}
`;
const MainContent = styled.div`
  z-index: 23;
  width: 100%;
  height: 100%;
  max-height: 620px;
  padding: 0 24px;
  ${media.tablet`
    padding: 0 20%;
  `}
`;
const LogoHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  ${media.sp`
    height: 36px;
  `}
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LogoImage = styled.h1`
  height: 100%;
`;
const ContentText = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  ${media.sp`
    margin-top: 23px;
  `}
`;
const ContentTextHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 3.2rem;
  line-height: 3.2rem;
  ${media.smallPc`
    font-size: 2.8rem;
    line-height: 2.8rem
  `}
  ${media.tablet`
    font-size: ${FONT_SIZE.XXLarge}rem;
    line-height: ${FONT_SIZE.XXLarge}rem
  `}
  ${media.sp`
    font-size: ${FONT_SIZE.Large}rem;
    line-height: ${FONT_SIZE.Large}rem
  `}
`;
const ContentTextMain = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  font-size: 8rem;
  line-height: 8rem;
  ${media.smallPc`
    font-size: 7rem;
    line-height: 7rem
  `}
  ${media.tablet`
    font-size: 6rem;
    line-height: 6rem
  `}
  ${media.sp`
    font-size: 4.8rem;
    line-height: 4.8rem;
  `}
  ${media.smallSp`
    font-size: 4rem;
    line-height: 4rem;
  `}
`;
const ContentTextBottom = styled.h3`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  font-size: ${FONT_SIZE.Medium}rem;
  line-height: ${FONT_SIZE.Medium}rem;
  ${media.sp`
    font-size: ${FONT_SIZE.Small}rem;
    line-height: ${FONT_SIZE.Small}rem;
  `}
  ${media.smallSp`
    padding: 20px 0;
  `}
`;
const LinkContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 40px;
`;
const Form = styled.form`
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const ReviewLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
const ReviewLink = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: scroll;
  font-size: ${FONT_SIZE.Large};
  cursor: pointer;
  background-color: ${COLORs.white};
  border: solid 1px;
  border-radius: 30px;
  ${media.sp`
    font-size: ${FONT_SIZE.Medium}rem;
  `};
`;
const UsageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;
const UsageCards = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;
const UsageCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: 140px;
  padding: 14px 12px;
  background-color: rgb(255 255 255 / 10%);
  border-radius: 10px;

  &:not(first-child) {
    margin-left: 1%;
  }
`;
const UsageCardNumber = styled.div`
  position: absolute;
  top: -18px;
  width: 100%;
  padding-left: 10%;
`;
const UsageCardContent = styled.div`
  display: flex;
  justify-content: center;
  height: 75%;
  padding: 10px 0;
`;
const UsageCardContent3 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const UsageCardDescription = styled.div`
  display: flex;
  justify-content: center;
  height: 25%;
`;
const AttentionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 69px;
  margin-top: 18px;
  border: 1px solid #585858;
  border-radius: 10px;
`;
const AttentionTitle = styled.div``;
const AttentionDescription = styled.div``;
const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 21;
  width: 100vw;
  height: 130vw;
  background-color: #353535;
  transform: translate(-95%, 5%) rotate(42deg);
  transform-origin: 100% 100%;
  ${media.tablet`
    transform: translateX(-110%) rotate(42deg);
  `}
  ${media.sp`
    height: 200vw;
    width: 200vw;
    bottom: 150px;
    transform: translateX(-100%) rotate(42deg);
  `}
`;
const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  ${media.smallSp`
    margin-top: 20px;
  `}
`;
const SpHidden = styled.div`
  ${media.tablet`
    display: none;
  `}
  ${media.sp`
    display: none;
  `}
  ${media.smallSp`
    display: none;
  `}
`;
const PcHidden = styled.div`
  ${media.pc`
    display: none;
  `}
  ${media.smallPc`
    display: none;
  `}
`;
const Root = () => {
  const dispatch = useDispatch();
  const [youtubeVideoId, setYoutubeVideoId] = useState(
    "https://youtu.be/UJHpBCqNUYA"
  );
  const [tooltip, setTooltip] = useState("クリックしてコピー");
  const [usageModal, setUsageModal] = useState(false);
  const [attentionModal, setAttentionModal] = useState(false);
  const publicReviewLink = useSelector(
    (state: any) => state.oneTimeTokenReducer.publicReviewLink
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [publicReviewLink, tooltip]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = [
      /^https:\/\/www\.youtube\.com\/watch\?v=.+/,
      /^https:\/\/youtu\.be\/.+/,
      /^https:\/\/www\.youtube\.com\/embed\/.+/,
    ].some((regex: any) => {
      return regex.test(youtubeVideoId);
    });
    if (!isValid) {
      alert("リンクが無効です");
      return;
    }
    dispatch(
      issueYouTubeReviewLinkFromLP(extractYouTubeVideoId(youtubeVideoId))
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(publicReviewLink).then(() => {
      setTooltip("コピーしました");
      window.setTimeout(() => {
        setTooltip("クリックしてコピー");
      }, 1500);
    });
  };

  // https://github.com/wwayne/react-tooltip/issues/27
  const copyMessage = () => {
    return tooltip;
  };
  return (
    <Container>
      <Main>
        <MovieSection>
          <MovieFlame>
            <PcDisplay>
              <video autoPlay muted loop width="100%" height="100%">
                <source src={reviewTrialVideo} type="video/mp4" />
              </video>
            </PcDisplay>
            <PcBodyTop />
            <PcBodyBottom />
          </MovieFlame>
        </MovieSection>
        <LinkSection>
          <MainContent>
            <LogoHeader>
              <Logo>
                <LogoImage>
                  <Image
                    src={crunchLogo}
                    alt="CRUNCH"
                    height="100%"
                    style={{
                      borderRadius: "6px",
                      border: `1px solid ${COLORs.white}`,
                    }}
                  />
                </LogoImage>
                <Svg
                  component={crunchTextLogo}
                  height="65%"
                  style={{ marginLeft: "8px" }}
                />
              </Logo>
            </LogoHeader>
            <ContentText>
              <ContentTextHeader>
                <Text
                  text="動画のレビューが"
                  color={COLORs.white}
                  weight={FONT_WEIGHT.XBold}
                />
              </ContentTextHeader>
              <ContentTextMain>
                <Text
                  text="驚くほど"
                  color={COLORs.white}
                  weight={FONT_WEIGHT.XBold}
                />
                <Text
                  text="簡単！"
                  color="#54DE5A"
                  weight={FONT_WEIGHT.XBold}
                />
              </ContentTextMain>
              <ContentTextBottom>
                <Text
                  text="ワンクリックでレビューページを作成！"
                  color={COLORs.white}
                />
              </ContentTextBottom>
            </ContentText>
            <LinkContent>
              {publicReviewLink ? (
                <ReviewLinkContainer>
                  <ReviewLink
                    onClick={handleCopy}
                    data-for="tooltip"
                    data-tip={copyMessage()}
                    data-place="top"
                  >
                    <Text
                      text={publicReviewLink}
                      color={COLORs.kayoTextDarken}
                      weight={FONT_WEIGHT.Bold}
                      style={{
                        whiteSpace: "nowrap",
                        padding: "14px 16px",
                      }}
                    />
                  </ReviewLink>
                  <RoundButton
                    text="確認"
                    clickHandler={() => window.open(publicReviewLink, "_blank")}
                    style={{
                      padding: "12px 14px",
                      marginLeft: "10px",
                      borderRadius: "30px",
                      height: "100%",
                    }}
                  />
                </ReviewLinkContainer>
              ) : (
                <Form onSubmit={handleSubmit}>
                  <InputText
                    name="youtubeVideoId"
                    value={youtubeVideoId}
                    placeholder="https://youtu.be/UJHpBCqNUYA"
                    changeHandler={(e) => setYoutubeVideoId(e.target.value)}
                    style={{
                      fontSize: `${FONT_SIZE.Large}rem`,
                      padding: "14px 16px",
                      color: `${COLORs.textRegular}`,
                      flexGrow: 1,
                      borderRadius: "30px",
                      height: "100%",
                    }}
                  />
                  <RoundButton
                    text="作成"
                    clickHandler={handleSubmit}
                    style={{
                      padding: "12px 24px",
                      marginLeft: "10px",
                      borderRadius: "30px",
                      height: "100%",
                    }}
                  />
                </Form>
              )}
            </LinkContent>
            <SpHidden>
              <UsageContent>
                <Text
                  text="ご利用方法"
                  color={COLORs.white}
                  size={FONT_SIZE.Medium}
                />
                <UsageCards>
                  <UsageCard>
                    <UsageCardNumber>
                      <Text
                        text="1"
                        color={COLORs.white}
                        size={FONT_SIZE.XLarge}
                      />
                    </UsageCardNumber>
                    <UsageCardContent>
                      <Svg component={usage1} height="70%" width="100%" />
                    </UsageCardContent>
                    <UsageCardDescription>
                      <Text
                        text="YouTube動画のURLをコピー"
                        color={COLORs.white}
                        size={FONT_SIZE.XSmall}
                      />
                    </UsageCardDescription>
                  </UsageCard>
                  <UsageCard>
                    <UsageCardNumber>
                      <Text
                        text="2"
                        color={COLORs.white}
                        size={FONT_SIZE.XLarge}
                      />
                    </UsageCardNumber>
                    <UsageCardContent>
                      <Svg component={usage2} height="50%" width="90%" />
                    </UsageCardContent>
                    <UsageCardDescription>
                      <Text
                        text="作成ボタンを押すとURLを発行"
                        color={COLORs.white}
                        size={FONT_SIZE.XSmall}
                      />
                    </UsageCardDescription>
                  </UsageCard>
                  <UsageCard>
                    <UsageCardNumber>
                      <Text
                        text="3"
                        color={COLORs.white}
                        size={FONT_SIZE.XLarge}
                      />
                    </UsageCardNumber>
                    <UsageCardContent>
                      <UsageCardContent3>
                        <Svg component={usage3} height="100%" width="100%" />
                        <Svg
                          component={movie}
                          height="31px"
                          style={{
                            position: "absolute",
                            left: "2%",
                            top: "calc(50% - 12px)",
                          }}
                        />
                      </UsageCardContent3>
                    </UsageCardContent>
                    <UsageCardDescription>
                      <Text
                        text="URLを共有して 動画レビュー"
                        color={COLORs.white}
                        size={FONT_SIZE.XSmall}
                      />
                    </UsageCardDescription>
                  </UsageCard>
                </UsageCards>
              </UsageContent>
              <AttentionContent>
                <AttentionTitle>
                  <Text
                    text="注意事項"
                    color={COLORs.white}
                    size={FONT_SIZE.XSmall}
                  />
                </AttentionTitle>
                <AttentionDescription>
                  <Text
                    text="・YouTubeリンクは公開か限定公開であることが必要です。"
                    color={COLORs.white}
                    size={FONT_SIZE.XSmall}
                  />
                  <Text
                    text="・動画レビューの有効期限は、URLの発行から24時間になります。"
                    color={COLORs.white}
                    size={FONT_SIZE.XSmall}
                  />
                </AttentionDescription>
              </AttentionContent>
            </SpHidden>
            <PcHidden>
              <ModalButtons>
                <RoundButton
                  text="ご利用方法"
                  clickHandler={() => setUsageModal(true)}
                  style={{
                    height: "40px",
                    width: "124px",
                    borderRadius: "30px",
                    padding: "12px 12px 12px 20px",
                    backgroundColor: "#252525",
                    border: "1px solid #585858",
                  }}
                  fontSize={FONT_SIZE.Small}
                  post={
                    <Svg
                      component={chevron}
                      height="16px"
                      width="16px"
                      style={{ marginLeft: "8px" }}
                    />
                  }
                />
                <RoundButton
                  text="注意事項"
                  clickHandler={() => setAttentionModal(true)}
                  style={{
                    height: "40px",
                    width: "124px",
                    borderRadius: "30px",
                    padding: "12px 12px 12px 20px",
                    backgroundColor: "#252525",
                    border: "1px solid #585858",
                  }}
                  fontSize={FONT_SIZE.Small}
                  post={
                    <Svg
                      component={chevron}
                      height="16px"
                      width="16px"
                      style={{ marginLeft: "8px" }}
                    />
                  }
                />
              </ModalButtons>
              <UsageModal
                closeHandler={() => setUsageModal(false)}
                display={usageModal}
              />
              <AttentionModal
                closeHandler={() => setAttentionModal(false)}
                display={attentionModal}
              />
            </PcHidden>
          </MainContent>
        </LinkSection>
        <ReactTooltip
          id="tooltip"
          place="top"
          effect="solid"
          multiline
          className="crunch-react-tooltip"
          getContent={copyMessage}
          backgroundColor={COLORs.white}
          textColor={COLORs.black}
        />
        <Footer>
          <Text
            text="Copyright 2022 (C) CRUNCH"
            size={FONT_SIZE.XXSmall}
            color={COLORs.white}
          />
        </Footer>
        <Triangle />
      </Main>
    </Container>
  );
};

export default Root;
