import { css, CSSObject, SimpleInterpolation } from "styled-components";

const media = {
  smallSp: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (max-width: 420px) {
      ${css(first, ...interpolations)}
    }
  `,
  sp: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (max-width: 768px) {
      ${css(first, ...interpolations)}
    }
  `,
  tablet: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (min-width: 769px) and (max-width: 1112px) {
      ${css(first, ...interpolations)}
    }
  `,
  smallPc: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (min-width: 1113px) and (max-width: 1440px) {
      ${css(first, ...interpolations)}
    }
  `,
  pc: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (min-width: 1113px) {
      ${css(first, ...interpolations)}
    }
  `,
  reviewBP: (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => css`
    @media (max-width: 894px) {
      ${css(first, ...interpolations)}
    }
  `,
};

export default media;
