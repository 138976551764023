import TypedHttpClient from "shared/libs/TypedHttpClient";
import PublicReviewLinkRes from "types/res/PublicReviewLinkTypeRes";

export const issueYouTubeReviewLinkFromLP = (youtubeVideoId: string) => {
  return async (dispatch: any) => {
    const result = await TypedHttpClient.post(
      PublicReviewLinkRes,
      "/api/v1/one_time_tokens/issue_from_lp",
      { youtubeVideoId: youtubeVideoId },
      dispatch
    );
    dispatch({
      type: "ISSUE_YOUTUBE_REVIEW_LINK_FROM_LP_SUCCESS",
      publicReviewLink: result.publicReviewLink,
    });
  };
};
