import * as React from "react";

type Props = {
  component: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconType?: "ICON_SMALL" | "ICON" | "ICON_LARGE" | "ICON_XLARGE";
  height?: string;
  width?: string;
  radius?: string;
  clickHandler?: func;
  style?: any;
  fillColor?: string;
  strokeColor?: string;
};
const Svg: React.FC<Props> = (props: Props) => {
  const width = () => {
    if (props.width) return props.width;
    switch (props.iconType) {
      case "ICON_SMALL":
        return "12px";
      case "ICON":
        return "16px";
      case "ICON_LARGE":
        return "18px";
      case "ICON_XLARGE":
        return "20px";
      default:
        return "initial";
    }
  };
  const height = () => {
    if (props.height) return props.height;
    switch (props.iconType) {
      case "ICON_SMALL":
        return "12px";
      case "ICON":
        return "16px";
      case "ICON_LARGE":
        return "18px";
      case "ICON_XLARGE":
        return "20px";
      default:
        return "initial";
    }
  };
  return (
    <props.component
      {...props}
      height={height()}
      width={width()}
      radius={props.radius}
      onClick={() => props.clickHandler && props.clickHandler()}
      style={{
        ...props.style,
        stroke: props.strokeColor,
        fill: props.fillColor,
      }}
    />
  );
};
export default Svg;
