import { Deserializer } from "jsonapi-serializer";

export default new Deserializer({
  keyForAttribute: "camelCase",
  transform: function (record) {
    Object.keys(record).forEach((key) => {
      // NOTES: null, undefined, ""の場合は何もせずcontinue(jsでforEachでcontinue = return)
      if (
        (record[key] !== 0 && !record[key]) ||
        typeof record[key] === "boolean"
      )
        return;
      record[key] = Number(record[key]) || record[key];
    });
    return record;
  },
});
