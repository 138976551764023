import React from "react";
import styled from "styled-components";
import COLORs from "shared/libs/color";
import { FONT_SIZE } from "shared/libs/constants";
import Text from "components/Text";
import Svg from "components/Svg";
import FullScreenModal from "components/FullScreenModal";
import { ReactComponent as x } from "assets/images/x.svg";

const Content = styled.div`
  padding: 0 20px;
`;
const CloseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 52px;
`;

type AttentionModalProps = {
  closeHandler: func;
  display: boolean;
};
const AttentionModal: React.FC<AttentionModalProps> = (
  props: AttentionModalProps
) => {
  if (!props.display) return null;

  return (
    <FullScreenModal display={props.display} closeHandler={props.closeHandler}>
      <Content>
        <CloseHeader>
          <Svg
            component={x}
            clickHandler={props.closeHandler}
            height="30px"
            width="30px"
            fillColor={COLORs.white}
          />
        </CloseHeader>
        <Title>
          <Text text="注意事項" size={2.0} color={COLORs.white} />
        </Title>
        <Description>
          <Text
            text="・YouTubeリンクは公開か限定公開であることが必要です。"
            color={COLORs.white}
            size={FONT_SIZE.Medium}
          />
          <Text
            text="・動画レビューの有効期限は、URLの発行から24時間になります。"
            color={COLORs.white}
            size={FONT_SIZE.Medium}
            style={{ marginTop: "16px" }}
          />
        </Description>
      </Content>
    </FullScreenModal>
  );
};

export default AttentionModal;
