export const FONT_SIZE = {
  XXSmall: 1.0,
  XSmall: 1.2,
  Small: 1.4,
  Medium: 1.6,
  Large: 1.8,
  XLarge: 2.0,
  XXLarge: 2.2,
} as const;

export const FONT_WEIGHT = {
  Regular: 400,
  Medium: 500,
  Bold: 700,
  XBold: 800,
} as const;
