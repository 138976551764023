export type OneTimeTokenState = {
  publicReviewLink: string;
};
const initialState: OneTimeTokenState = {
  publicReviewLink: "",
};
export default function oneTimeTokenReducer(
  state = initialState,
  action: {
    type: string;
    publicReviewLink: string;
  }
) {
  switch (action.type) {
    case "ISSUE_YOUTUBE_REVIEW_LINK_FROM_LP_SUCCESS": {
      return {
        ...state,
        publicReviewLink: action.publicReviewLink,
      };
    }
    default:
      return state;
  }
}
