import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "components/Text";
import Image from "components/Image";
import Svg from "components/Svg";
import COLORs from "shared/libs/color";
import media from "shared/libs/media";
import { FONT_SIZE, FONT_WEIGHT } from "shared/libs/constants";
import { ReactComponent as loadingIcon } from "assets/images/loading.svg";

const Container = styled.div<{
  presentIcon: boolean;
  danger: boolean;
  warning: boolean;
  disable: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  padding: 5px 16px;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${(props) =>
    props.danger
      ? COLORs.danger
      : props.warning
      ? COLORs.warning
      : COLORs.main};
  border-radius: 4px;
  transition-duration: 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.danger
        ? COLORs.dangerHover
        : props.warning
        ? COLORs.warningHover
        : COLORs.mainHover};
  }
  ${(props) =>
    props.disable &&
    `
    background-color: ${COLORs.disableButton};
    pointer-events: none;
    cursor: not-allowed;
    &:hover {
      background-color: ${COLORs.disableButton};
    }
  `}
  ${(props) => props.presentIcon && "display: flex; align-items: center;"}
  ${media.reviewBP`
    padding: 3px 10px;
  `}
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
`;
const Icon = styled.span``;

type Props = {
  text: string;
  post?: React.ReactNode;
  clickHandler?: func;
  icon?: string;
  postIcon?: string;
  danger?: boolean;
  warning?: boolean;
  disable?: boolean;
  disablable?: boolean;
  loading?: boolean;
  style?: Record<string, unknown>;
  fontSize?: ValueOf<typeof FONT_SIZE>;
  textColor?: ValueOf<typeof COLORs>;
};
const RoundButton: React.FC<Props> = (props: Props) => {
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(!!props.disable);
  }, [props.disable]);

  const handleClick = (e: any) => {
    if (!props.clickHandler || disable) return;

    if (props.disablable) {
      setDisable(true);
      setTimeout(() => setDisable(false), 2000);
    }
    props.clickHandler(e);
  };

  return (
    <Container
      presentIcon={!!props.icon}
      danger={props.danger}
      warning={props.warning}
      disable={disable}
      style={
        props.loading
          ? Object.assign(props.style, { backgroundColor: COLORs.mainHover })
          : props.style
      }
      onClick={handleClick}
    >
      <Inner>
        {props.loading ? (
          <Svg component={loadingIcon} height="18px" width="18px" />
        ) : (
          <>
            {props.icon && (
              <Icon style={{ marginRight: "4px" }}>
                <Image src={props.icon} alt="Button icon" height="12px" />
              </Icon>
            )}
            <Text
              text={props.text}
              weight={FONT_WEIGHT.Medium}
              size={props.fontSize || FONT_SIZE.Medium}
              style={{ color: props.textColor || "#fff" }}
            />
            {props.post && props.post}
            {props.postIcon && (
              <Icon style={{ marginLeft: "4px" }}>
                <Image src={props.postIcon} alt="Button icon" height="12px" />
              </Icon>
            )}
          </>
        )}
      </Inner>
    </Container>
  );
};
export default RoundButton;
