import React from "react";
import styled from "styled-components";
import Svg from "components/Svg";
import Text from "components/Text";
import TextLink from "components/TextLink";
import COLORs from "shared/libs/color";
import { FONT_SIZE, FONT_WEIGHT } from "shared/libs/constants";
import { ReactComponent as ErrorImage } from "assets/images/error-dog.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ddd;

  span p {
    font-weight: 800;
  }
`;
function ErrorView() {
  return (
    <Container>
      <Svg component={ErrorImage} height="160px" />
      <Text
        text="Oops!"
        color={COLORs.textDarken}
        weight={FONT_WEIGHT.Bold}
        style={{ fontSize: "4.4rem", marginBottom: "24px" }}
      />
      <Text
        text="エラーが発生しました。現在、開発チームが調査・対応にあたっています。"
        color={COLORs.textLight}
        weight={FONT_WEIGHT.Bold}
        size={FONT_SIZE.Medium}
      />
      <Text
        text="このままサービスをご利用になられる場合は、ページを再読み込みしてください。"
        color={COLORs.textLight}
        weight={FONT_WEIGHT.Bold}
        size={FONT_SIZE.Medium}
        style={{ marginBottom: "8px" }}
      />
      <TextLink
        text="ページの再読み込みをする"
        clickHandler={() => window.location.reload()}
      />
    </Container>
  );
}
export default ErrorView;
