import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Provider } from "react-redux";
import configureAppStore from "app/store/configureAppStore";
import Root from "app/pages/Root";

const App = () => {
  const store = configureAppStore();

  const [cookies, setCookie] = useCookies(["utm_campaign"]);
  const search = useLocation().search;
  const utmCampaign = new URLSearchParams(search).get("utm_campaign");

  useEffect(() => {
    if (
      (!cookies.utm_campaign ||
        (cookies.utm_campaign && cookies.utm_campaign !== utmCampaign)) &&
      utmCampaign
    ) {
      setCookie("utm_campaign", utmCampaign, { maxAge: 604800 });
    }
  }, []);

  useEffect(() => {
    if (!window.gtag) return;
    (window as any).gtag("js", new Date());
    (window as any).gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID);
  }, [window]);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="*" element={<Root />} />
      </Routes>
    </Provider>
  );
};

export default App;
