import { instanceToPlain, plainToInstance } from "class-transformer";
import { validate } from "class-validator";
import { ClassConstructor } from "class-transformer/types/interfaces";

export default class ClassUtils {
  private constructor() {
    //  do nothing
  }

  static async convertToClass<T, V>(
    cls: ClassConstructor<T>,
    data: V,
    valueOption = false
  ): Promise<T> {
    const i = plainToInstance(cls, data);
    return ClassUtils.validateClass(i, valueOption);
  }

  static async validateClass<T>(data: T, valueOption = false): Promise<T> {
    const options = {
      whitelist: false,
      validationError: {
        target: false,
        value: valueOption,
      },
    };
    const errors = await validate(Object.assign({}, data), options);
    if (errors.length > 0) {
      throw errors;
    }
    return data;
  }

  public static convertToResponse<T, U extends T>(
    cls: ClassConstructor<T>,
    plain: U
  ): T {
    return plainToInstance(cls, instanceToPlain(plain), {
      strategy: "excludeAll",
      excludeExtraneousValues: true,
    });
  }

  public static convertToArrayResponse<T, U extends T>(
    cls: ClassConstructor<T>,
    plain: U[]
  ): T[] {
    return plainToInstance(cls, instanceToPlain(plain) as T[], {
      strategy: "excludeAll",
      excludeExtraneousValues: true,
    });
  }
}
