import { Expose, Type } from "class-transformer";

export default class BaseRes {
  @Expose() readonly id: number;
  @Type(() => Date)
  @Expose()
  createdAt: Date;
  @Type(() => Date)
  @Expose()
  updatedAt: Date;
  @Expose() readonly createdById: number;
  @Expose() readonly updatedById: number;
}
