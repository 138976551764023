import React from "react";
import styled from "styled-components";
import COLORs from "shared/libs/color";
import { FONT_SIZE } from "shared/libs/constants";
import Text from "components/Text";
import Svg from "components/Svg";
import FullScreenModal from "components/FullScreenModal";
import { ReactComponent as x } from "assets/images/x.svg";
import { ReactComponent as usage1 } from "assets/images/usage1.svg";
import { ReactComponent as usage2 } from "assets/images/usage2.svg";
import { ReactComponent as usage3 } from "assets/images/usage3.svg";

const Content = styled.div`
  padding: 0 20px;
`;
const CloseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
`;
const UsageCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 45px;
  margin: 45px 0;
`;
const UsageCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 193px;
  background-color: rgb(255 255 255 / 10%);
  border-radius: 10px;

  &:not(:nth-child(1)) {
    margin-top: 34px;
  }
`;
const UsageCardNumber = styled.div`
  position: relative;
  top: -32px;
  left: 20px;
  width: 100%;
`;
const UsageCardContent = styled.div`
  display: flex;
  justify-content: center;
  height: 50%;
`;
const UsageCardContent3 = styled.div`
  position: relative;
  top: -32px;
  width: 100%;
  height: 100%;
`;
const UsageCardDescription = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 14px;
`;

type UsageModalProps = {
  closeHandler: func;
  display: boolean;
};
const UsageModal: React.FC<UsageModalProps> = (props: UsageModalProps) => {
  if (!props.display) return null;

  return (
    <FullScreenModal display={props.display} closeHandler={props.closeHandler}>
      <Content>
        <CloseHeader>
          <Svg
            component={x}
            clickHandler={props.closeHandler}
            height="30px"
            width="30px"
            fillColor={COLORs.white}
          />
        </CloseHeader>
        <Title>
          <Text
            text="ご利用方法"
            size={FONT_SIZE.XLarge}
            color={COLORs.white}
          />
        </Title>
        <UsageCards>
          <UsageCard>
            <UsageCardNumber>
              <Text text="1" color={COLORs.white} size={2.7} />
            </UsageCardNumber>
            <UsageCardContent>
              <Svg component={usage1} height="70%" width="100%" />
            </UsageCardContent>
            <UsageCardDescription>
              <Text
                text="YouTube動画のURLをコピー"
                color={COLORs.white}
                size={FONT_SIZE.Medium}
              />
            </UsageCardDescription>
          </UsageCard>
          <UsageCard>
            <UsageCardNumber>
              <Text text="2" color={COLORs.white} size={2.7} />
            </UsageCardNumber>
            <UsageCardContent>
              <Svg component={usage2} height="50%" width="90%" />
            </UsageCardContent>
            <UsageCardDescription>
              <Text
                text="作成ボタンを押すとURLを発行"
                color={COLORs.white}
                size={FONT_SIZE.Medium}
              />
            </UsageCardDescription>
          </UsageCard>
          <UsageCard>
            <UsageCardNumber>
              <Text text="3" color={COLORs.white} size={2.7} />
            </UsageCardNumber>
            <UsageCardContent>
              <UsageCardContent3>
                <Svg component={usage3} height="100%" width="100%" />
              </UsageCardContent3>
            </UsageCardContent>
            <UsageCardDescription>
              <Text
                text="URLを共有して 動画レビュー"
                color={COLORs.white}
                size={FONT_SIZE.Medium}
              />
            </UsageCardDescription>
          </UsageCard>
        </UsageCards>
      </Content>
    </FullScreenModal>
  );
};

export default UsageModal;
