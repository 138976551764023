const COLORs = {
  main: "#1B76EA",
  mainHover: "#7BABFF",
  mainTransparency: "#BAD4FF",
  secondary: "#F3B96F",
  textXXLight: "#DDDDDD",
  textXLight: "#BBBBBB",
  textLight: "#999999",
  textRegular: "#666666",
  textDarken: "#333333",
  formBg: "#F2F2F2",
  placeholder: "#BBBBBB",
  border: "#DDDDDD",
  borderOnFocus: "#5F9AFF",
  userIconBg: "#B3B3B3",
  dashedBorder: "#666666",
  modalFooter: "#F6F8FA",
  textLink: "#5F9AFF",
  warning: "#FC8B35", // ユーザーが随意的に操作できるものをwarningとする
  warningHover: "#FF9B45",
  warningBg: "#F6F1E7",
  error: "#F44545",
  danger: "#F44545",
  dangerHover: "#EF7A7A",
  white: "#ffffff",
  disableButton: "#929292",
  /* CreativeFile.status */
  kayoTextDarken: "#000000",
  kayoTextLightDarken: "#191919",
  kayoTextXLight: "#8b8b8b",
  kayoTextXXXLight: "#dbdbdb",
  kayoXLightGrayBg: "#f8f8f8",
  kayoLightGrayBg: "#f2f2f2",
  kayoGrayBg: "#e8e8e8",
  kayoDarkGrayBg: "#dedede",
  kayoGrayBorder: "#e2e2e2",
  kayoTagBlack: "#5a5a5a",
  /* UX再設計後カラーコード */
  black: "#000000",
} as const;
export default COLORs;
