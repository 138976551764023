import { ClassConstructor } from "class-transformer/types/interfaces";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Bugsnag from "@bugsnag/js";
import DeserializerClient from "shared/actions/DeserializerClient";
import ClassUtils from "shared/libs/ClassUtils";
import { Action, Dispatch } from "redux";
import { camelizeKeys, decamelizeKeys } from "humps";

export default class TypedHttpClient {
  private constructor() {
    // do nothing
  }

  public static async post<T>(
    cls: ClassConstructor<T>,
    path: string,
    data: any,
    dispatch: Dispatch<Action>,
    requestConfig: AxiosRequestConfig = {},
    isJsonApi = true
  ) {
    requestConfig.url = path;
    requestConfig.data = decamelizeKeys(data);
    const instance = this.createInstance();
    try {
      const res = await instance.post(
        requestConfig.url,
        decamelizeKeys(data),
        requestConfig
      );
      const deserializeData = isJsonApi
        ? await DeserializerClient.deserialize(res.data)
        : camelizeKeys(res.data);
      return ClassUtils.convertToResponse(cls, deserializeData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.handleAxiosError(error, dispatch);
      } else {
        this.handleUnexpectedError(error, dispatch);
      }
    }
  }

  private static createInstance() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_HOST,
        Accept: "application/json",
      },
      withCredentials: true,
    });
  }

  private static handleAxiosError(
    error: AxiosError<any>,
    dispatch: Dispatch<Action>
  ) {
    if (error.response) {
      dispatch({
        type: "HANDLE_RESPONSE_ERROR",
        errMsg: error.response.data.message,
      });
    } else if (error.request) {
      dispatch({
        type: "HANDLE_RESPONSE_ERROR",
        errMsg: error.request,
      });
    }
    Bugsnag.notify(error);
    throw error;
  }

  private static handleUnexpectedError(error: any, dispatch: Dispatch<Action>) {
    dispatch({
      type: "HANDLE_RESPONSE_ERROR",
      errMsg: error.message,
    });
    Bugsnag.notify(error);
    throw error;
  }
}
