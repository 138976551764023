import * as React from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  width: 100%;
  height: 100%;
  background-color: #252525;
  animation: 0.2s ${keyframes`${fadeIn}`};
`;
type Props = {
  display: boolean;
  children: React.ReactNode;
  closeHandler?: func;
  style?: Record<string, unknown>;
};
const Modal: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    const escHandler = (e: any) => {
      if (e.key === "Escape" || e.key === "Esc")
        props.closeHandler && props.closeHandler();
    };

    document.addEventListener("keydown", escHandler);
    return () => {
      document.removeEventListener("keydown", escHandler);
    };
  }, []);

  if (!props.display) return null;

  return <Container>{props.children}</Container>;
};
export default Modal;
