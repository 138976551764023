import * as React from "react";
import styled from "styled-components";
import COLORs from "shared/libs/color";

const Input = styled.input<{ disabledFocusShadow: boolean }>`
  width: 100%;
  padding: 10px 14px;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${COLORs.textRegular};
  cursor: pointer;
  background-color: ${COLORs.formBg};
  border: none;
  border-radius: 6px;

  &::placeholder {
    color: ${COLORs.placeholder};
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props.disabledFocusShadow ? "none" : `0 0 0 1px ${COLORs.textRegular}`};
  }
`;

type Props = {
  id?: string;
  className?: string;
  name: string;
  value?: any;
  defaultValue?: any;
  placeholder?: string;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clickHandler?: any;
  blurHandler?: any;
  autoFocus?: boolean;
  style?: any;
  disabledFocusShadow?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
const InputText: React.FC<Props> = (props: Props) => {
  return (
    <Input
      type="text"
      id={props.id}
      className={props.className || ""}
      name={props.name}
      value={props.value}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      onChange={props.changeHandler}
      onClick={props.clickHandler}
      onBlur={props.blurHandler}
      autoFocus={props.autoFocus}
      autoComplete="off"
      style={props.style}
      disabledFocusShadow={props.disabledFocusShadow}
      {...props}
    />
  );
};
export default InputText;
