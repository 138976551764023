import { applyMiddleware, compose, createStore } from "redux";
import appReducer from "app/reducers/appRootReducer";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default function configureAppStore() {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk));
  const persistConfig = {
    key: "CRUNCH_REVIEW_APP_STATE",
    storage,
    whitelist: [""],
  };
  const persistedReducer = persistReducer(persistConfig, appReducer);
  return createStore(persistedReducer, enhancer);
}
