import * as React from "react";
import styled from "styled-components";
import Text from "components/Text";
import Image from "components/Image";
import COLORs from "shared/libs/color";
import externalLink from "assets/images/externalLink.svg";
import { FONT_SIZE, FONT_WEIGHT } from "../shared/libs/constants";

const Link = styled.span<{ ellipsis: boolean }>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  ${(props) => props.ellipsis && "overflow: hidden;"}

  &:hover {
    p {
      font-weight: ${FONT_WEIGHT.Bold};
    }
  }
`;

type Props = {
  text: string;
  clickHandler: func;
  danger?: boolean;
  ellipsis?: boolean;
  targetBlank?: boolean;
  style?: Record<string, unknown>;
};
const TextLink: React.FC<Props> = (props: Props) => {
  return (
    <Link
      onClick={props.clickHandler}
      ellipsis={props.ellipsis}
      style={props.style}
    >
      <Text
        text={props.text}
        weight={FONT_WEIGHT.Regular}
        size={FONT_SIZE.Small}
        style={
          props.ellipsis
            ? {
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: props.danger ? COLORs.danger : COLORs.textLink,
              }
            : { color: props.danger ? COLORs.danger : COLORs.textLink }
        }
      />
      {props.targetBlank && (
        <Image src={externalLink} alt="target-blank" imageType="ICON_SMALL" />
      )}
    </Link>
  );
};
export default TextLink;
