// class-transformer's requirement
import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import ErrorView from "./ErrorView";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BrowserRouter } from "react-router-dom";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ["production", "staging", "development"],
  releaseStage: process.env.REACT_APP_APPLICATION_ENV,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorView}>
      <CookiesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
